import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import { store } from './store';
import "./sass/main.scss";
import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "dayjs/locale/de";
//theme
import "primereact/resources/themes/fluent-light/theme.css";

//core
import "primereact/resources/primereact.min.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider>
      <DatesProvider settings={{ locale: "de" }}>
        <Provider store={store}>
          <App />
        </Provider>
      </DatesProvider>
    </MantineProvider>
  </React.StrictMode>
);

