import logo1 from "../../../assets/images/icons/icon-1.png";
import logo2 from "../../../assets/images/icons/icon-2.png";
import logo3 from "../../../assets/images/icons/icon-3.png";
import logo4 from "../../../assets/images/icons/icon-4.png";
import logo5 from "../../../assets/images/icons/icon-5.png";
import { getUserFromLocalStorage } from "../../../utils/localStorage";
import axiosInstance from "../../../utils/axios";
import formatNumber from "../../../utils/formatNumber";
import React, { useState, useEffect } from "react";
import getWeeks from "../../../utils/getWeeks";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import DateSelector from "../../../components/DateSelector";
import dayjs from "dayjs";

function DistributorProfilePage() {
  const user = getUserFromLocalStorage();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(
    getWeeks().reverse()[0]
  );
  const [selectedRange, setSelectedRange] = useState([dayjs(), dayjs().subtract(7, "day")]);
  // const getData = async (week) => {
  //   console.log("👉 loading data");
  //   setLoading(true);

  //   const response = await axiosInstance.get(`/api/distributor/main/${week}`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   });

  //   const result = await response.data.result;
  //   setData(result);
  //   setLoading(false);
  // };
  // const getData = async (startDate, endDate) => {
  //   console.log("👉 loading data");
  //   console.log(startDate, endDate);

  //   // setLoading(true);

  //   // const response = await axiosInstance.get(`/api/distributor/main/${startDate}-${endDate}`, {
  //   //   headers: {
  //   //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //   //   },
  //   // });

  //   // const result = await response.data.result;
  //   // setData(result);
  //   // setLoading(false);
  // };

  const getData = async () => {
    console.log("👉 loading data");
    if (selectedRange[0] && selectedRange[1]) {
      setLoading(true);
      const response = await axiosInstance.get(`/api/distributor/main/${selectedRange[0].toISOString()},${selectedRange[1].toISOString()}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const result = await response.data.result;
      setData(result);
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [selectedRange]);

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <>
      {/* <!-- HEADER --> */}
      <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-2 mb-3">
        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
          <div className="welcome-message">
            <h3 className="fs-2">Hallo {user.fullName},</h3>
            <span>Schau dir deine Statistiken an.</span>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-6 col-xl-6  d-flex align-items-center justify-content-end">
          <DateSelector
            value={selectedRange}
            onChange={(selectedValue) => {
              console.log("👉 selectedValue", selectedValue);
              setSelectedRange(selectedValue);
              if (selectedValue[0] && selectedValue[1]) {

                getData();
              }
            }}
          />

        </div>
      </div>
      {/* <!-- END OF HEADER --> */}

      <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-2 mb-3">
        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
          <div className="card info-card">
            <div className="card-header">
              <img
                src={logo1}
                alt="icon-1"
                className="img-fluid card-icon float-end"
              />
            </div>
            <div className="card-body">
              <span className="body-text">
                {formatNumber(data?.totalCommission)}
              </span>
              <p className="footer-text">
                <p className="footer-text">
                  Provision
                  <br />
                  diese Woche
                </p>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
          <div className="card border-0 rounded-3 info-card">
            <div className="card-header bg-light border-0">
              <img
                src={logo2}
                alt="icon-1"
                className="img-fluid card-icon float-end"
              />
            </div>
            <div className="card-body">
              <span className="body-text">
                {formatNumber(data?.totalIncome)}
              </span>
              <p className="footer-text">
                Prämien
                <br />
                diese Woche
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3 mb-3">
        <div className="col-12 col-md-12 col-lg-6 col-xl-4">
          <div className="card info-card">
            <div className="card-header">
              <img
                src={logo3}
                alt="icon-1"
                className="img-fluid card-icon float-end"
              />
            </div>
            <div className="card-body">
              <p className="body-text">{data?.completedThisWeek}</p>
              <p className="footer-text">
                abgeschlossene
                <br />
                Verträge diese Woche
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-6 col-xl-4">
          <div className="card info-card">
            <div className="card-header">
              <img
                src={logo4}
                alt="icon-1"
                className="img-fluid card-icon float-end"
              />
            </div>
            <div className="card-body">
              <p className="body-text">{data?.createdThisWeek}</p>
              <p className="footer-text">
                erstellte Angebote
                <br />
                diese Woche
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-6 col-xl-4">
          <div className="card info-card">
            <div className="card-header">
              <img
                src={logo5}
                alt="icon-1"
                className="img-fluid card-icon float-end"
              />
            </div>
            <div className="card-body">
              <p className="body-text">{data?.newCustomersCount}</p>
              <p className="footer-text">
                neue Kund*innen
                <br />
                diese Woche
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row mb-3 top-three-section"
      // style={{ minHeight: "30rem", height: "30rem" }}
      >
        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <div className="card info-card height-auto">
                <div className="card-body">
                  <p className="footer-text">
                    Neueste versicherte Maschinengruppen
                  </p>
                  <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3 ">
                    {data &&
                      data?.lastThreeMachines &&
                      data?.lastThreeMachines?.map((record, index) => {
                        return (
                          <div
                            key={index}
                            className="col-12 col-md-12 col-lg-6 col-xl-4 "
                          >
                            <div className="card product-card border-0">
                              <div className="card-header border-0 rounded-3 p-0 m-0">
                                <img
                                  src={require(`../../../assets/categoryImages/${record?.category?.photo}`)}
                                  alt="product-1"
                                  className="img-fluid rounded-3 product-img"
                                />
                              </div>
                              <div className="card-body bg-light">
                                <p className="text-center my-auto lead">
                                  {index + 1}. {record?.category?.title}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-cols-md-2 row-cols-lg-2 mb-3 dash-bottom">
        <div className="col-12 col-md-12 col-lg-5">
          <div className="card info-card table-card">
            <div className="card-body bg-light rounded-3">
              <p className="footer-text">Zur Unterschrift gesendet</p>
              <table className="table table-borderless">
                <tbody className="border-none">
                  {data &&
                    data?.lastThreeCompletedContracts &&
                    data.lastThreeCompletedContracts?.map((record, index) => {
                      return (
                        <tr key={index} className="">
                          <td className="text-uppercase text-dark">
                            {record?.customer?.company?.name}
                          </td>
                          <td className="text-dark">
                            {record?.category}, {record?.machine}
                          </td>
                          <td className="icon-cell text-dark">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="30.31"
                              height="22.738"
                              viewBox="0 0 30.31 22.738"
                            >
                              <g
                                id="Group_73"
                                data-name="Group 73"
                                clipPath="url(#clipPath)"
                              >
                                <path
                                  id="Path_56"
                                  data-name="Path 56"
                                  d="M30.31,4.206V18.532a.989.989,0,0,0-.054.163A4.717,4.717,0,0,1,25.63,22.73q-10.475.016-20.95,0A4.711,4.711,0,0,1,.17,19.244c-.064-.235-.114-.475-.17-.712V4.206a1.021,1.021,0,0,0,.053-.164A4.723,4.723,0,0,1,4.709.006Q15.155-.006,25.6.005a4.71,4.71,0,0,1,4.545,3.517c.061.226.109.455.164.683M15.129,20.84H25.4a2.848,2.848,0,0,0,3.017-3.024q0-6.451,0-12.9A2.847,2.847,0,0,0,25.39,1.9H4.912A2.849,2.849,0,0,0,1.895,4.921q0,6.451,0,12.9A2.851,2.851,0,0,0,4.92,20.84H15.129"
                                  transform="translate(0 0)"
                                  fill="#30426a"
                                />
                                <path
                                  id="Path_57"
                                  data-name="Path 57"
                                  d="M64.422,55.638a3.486,3.486,0,0,1,.311.24q2.817,2.812,5.631,5.626a.966.966,0,0,1,.312,1.014.938.938,0,0,1-1.5.467,3.126,3.126,0,0,1-.235-.225q-2.773-2.772-5.544-5.546c-.076-.076-.14-.164-.224-.265-.11.1-.189.173-.263.247q-1.392,1.391-2.784,2.782a.964.964,0,0,1-1.543,0c-1-1-1.992-2-3.088-3.094a1.271,1.271,0,0,1-.164.306q-2.809,2.82-5.632,5.625a1.4,1.4,0,0,1-.651.364.849.849,0,0,1-.925-.443.926.926,0,0,1,.061-1.055,2.647,2.647,0,0,1,.223-.237q2.783-2.783,5.567-5.565a3.114,3.114,0,0,1,.275-.217c-.11-.118-.18-.2-.254-.271q-2.824-2.825-5.651-5.648a.994.994,0,0,1-.338-.929.945.945,0,0,1,1.525-.553,3.1,3.1,0,0,1,.235.225q4.667,4.666,9.332,9.333a1.957,1.957,0,0,0,.334.313,1.365,1.365,0,0,1,.177-.308q4.661-4.671,9.329-9.335c.524-.524,1.078-.585,1.5-.166s.365.979-.157,1.5q-2.772,2.773-5.546,5.544c-.076.076-.164.141-.314.27"
                                  transform="translate(-44.199 -44.255)"
                                  fill="#30426a"
                                />
                              </g>
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-7">
          <div className="card info-card table-card">
            <div className="card-body bg-light rounded-3">
              <p className="footer-text">Offene Verträge</p>
              <table className="table table-borderless">
                <tbody className="border-none">
                  {data &&
                    data?.lastThreeCreatedContracts &&
                    data?.lastThreeCreatedContracts?.map((record, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="text-uppercase text-dark">
                            {record?.customer?.company?.name}
                          </td>
                          <td className="text-dark">
                            {record?.customer?.fullName}
                          </td>
                          <td>
                            {record?.category}, {record?.machine}
                          </td>
                          <td className="icon-cell text-dark">
                            {record.state === "OfferCreated" && (
                              <Link
                                to={`/calculator/update/${record?._id}`}
                                className="btn edit-btn btn-sm btn-outline-dark"
                              >
                                Bearbeiten
                              </Link>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DistributorProfilePage;
